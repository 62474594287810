* {
    padding : 0;
    margin  : 0;
}

#bizzbar {
    background  : #FFBF00;
    width       : 100%;
    text-align  : center;
    color       : #FFFFFF;
    padding     : 2px;
    overflow    : hidden;
    z-index     : 1000;
    position    : sticky;
    top         : 0;
    left        : 0;
    box-shadow  : 0 1px 5px rgba(0, 0, 0, .5)
}

.subscriptions:hover {
    cursor     : pointer;
    background : #444444
}

.subscriptions:active {
    top : 1px
}

close {
    width      : 20px;
    height     : 19px;
    bottom     : 6px;
    right      : 20px;
    background : url(https://hb-assets.s3.amazonaws.com/system/modules/hellobar/lib/sprite-8bit.png) no-repeat 0px -247px;
    position   : absolute
}

close:hover {
    background : url(https://hb-assets.s3.amazonaws.com/system/modules/hellobar/lib/sprite-8bit.png) no-repeat 0px -228px;
    cursor     : pointer;
    height     : 19px
}

/*
open { -webkit-box-shadow : rgba(0, 0, 0, 0.34902) 0px 0px 5px; background-image : url(https://hb-assets.s3.amazonaws.com/system/modules/hellobar/lib/sprite-8bit.png); background-position : 0px -8px; background-repeat : no-repeat no-repeat; border-bottom-left-radius : 5px; border-bottom-right-radius : 5px; border : 3px solid rgb(255, 255, 255); box-shadow : rgba(0, 0, 0, 0.34902) 0px 0px 5px; display : block; height : 0px; outline : none; overflow : hidden; padding : 80px 0px 0px; position : fixed; right : 10px; top : -50px; width : 41px; z-index : 100; background-color : #EB593C; display : none }
*/
open {
    -webkit-box-shadow         : rgba(0, 0, 0, 0.34902) 0px 0px 5px;
    border-bottom-left-radius  : 5px;
    border-bottom-right-radius : 5px;
    border                     : 3px solid rgb(255, 255, 255);
    border-top                 : 0;
    box-shadow                 : rgba(0, 0, 0, 0.34902) 0px 0px 5px;
    display                    : block;
    outline                    : none;
    overflow                   : hidden;
    padding                    : 10px;
    position                   : fixed;
    right                      : 10px;
    top                        : 0;
    z-index                    : 100;
    background-color           : #EB593C;
    display                    : none
}

open:hover {
    background-color : #FF5A3D;
    cursor           : pointer
}

/*Subscription Questions Section*/
