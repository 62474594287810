/* This stylesheet generated by Transfonter (http://transfonter.org) on January 10, 2017 8:55 AM */

@font-face {
    font-family: 'DINOT medium';
    src: url('DINOT-Medium.eot');
    src: url('DINOT-Medium.eot?#iefix') format('embedded-opentype'),
    url('DINOT-Medium.woff') format('woff'),
    url('DINOT-Medium.ttf') format('truetype'),
    url('DINOT-Medium.svg#DINOT-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'DINOT black';
    src: url('DINOT-Black.eot');
    src: url('DINOT-Black.eot?#iefix') format('embedded-opentype'),
    url('DINOT-Black.woff') format('woff'),
    url('DINOT-Black.ttf') format('truetype'),
    url('DINOT-Black.svg#DINOT-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Minion Pro italic';
    src: url('MinionPro-It.eot');
    src: url('MinionPro-It.eot?#iefix') format('embedded-opentype'),
    url('MinionPro-It.woff') format('woff'),
    url('MinionPro-It.ttf') format('truetype'),
    url('MinionPro-It.svg#MinionPro-It') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'DINOT bold';
    src: url('DINOT-Bold.eot');
    src: url('DINOT-Bold.eot?#iefix') format('embedded-opentype'),
    url('DINOT-Bold.woff') format('woff'),
    url('DINOT-Bold.ttf') format('truetype'),
    url('DINOT-Bold.svg#DINOT-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Minion Pro regular';
    src: url('MinionPro-Regular.eot');
    src: url('MinionPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('MinionPro-Regular.woff') format('woff'),
    url('MinionPro-Regular.ttf') format('truetype'),
    url('MinionPro-Regular.svg#MinionPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DINOT regular';
    src: url('DINOT-Regular.eot');
    src: url('DINOT-Regular.eot?#iefix') format('embedded-opentype'),
    url('DINOT-Regular.woff') format('woff'),
    url('DINOT-Regular.ttf') format('truetype'),
    url('DINOT-Regular.svg#DINOT-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Minion Pro bold';
    src: url('MinionPro-Bold.eot');
    src: url('MinionPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('MinionPro-Bold.woff') format('woff'),
    url('MinionPro-Bold.ttf') format('truetype'),
    url('MinionPro-Bold.svg#MinionPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@-ms-viewport {

    width: device-width;

}

* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    font-family: arial, serif;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    outline: none;
}

a, a:hover, a:active {
    color: #565656;
    text-decoration: none;
}

a:hover, a:focus {
    color: #565656;
}

body {
    overflow-x: hidden;
    /*opacity: 0;*/
}

.bold {
    font-weight: bold;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

ul, li {
    list-style-type: none;
}

.italic {
    font-style: italic;
}

.bold {
    font-weight: bold !important;
}

.no-left-padding {
    padding-left: 0;
}

.no-top-margin {
    margin-top: 0 !important;
}

.img-full {
    width: 100%;
}

.top-add {
    margin: 30px 0 15px 0;
}

a.info {
    color: #0d6aba;
    text-decoration: none;
}

a.info:hover {
    text-decoration: underline;
    color: red;
}

p {
    font-size: 16px;
    line-height: 24px;
}

header .wrap-search input[type=submit] {
    font-family: inherit;
    font-size: 11px;
    font-weight: bold;
    color: #fff;
    padding: 0 8px;
    height: 29px;
    min-width: 70px;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border: 1px solid #666666;
    float: left;
    background: #0D6ABA url('/media/images/site/search/search_box_icon.png') no-repeat center center;
    text-indent: -9999px;
    margin-left: 15px;
}

header .wrap-search input[type=text] {
    padding: 2px 10px;
    float: left;
}

header .row {
    padding: 15px 0;
}

header .row .wrap-search {
    margin-top: 30px;
}

header .row nav > ul {
    list-style-type: none;
    display: flex;
}

header .row ul {
    margin-bottom: 0;
}

header .row ul li {
    display: inline-block;
    border-left: 1px solid #002a5e;
    border-top: 1px solid #002a5e;
    border-bottom: 1px solid #002a5e;
    position: relative;
}

header .row ul li:last-of-type {
    border-right: 1px solid #002a5e;
}

header .row ul li a {
    text-transform: uppercase;
}

header .ribbon {
    background: url('/media/images/site/menu/blue_top.png') repeat;
    margin-bottom: 20px;
}

header .ribbon .row {
    padding: 0;
}

header nav {
    background: url('/media/images/site/menu/menu-bg.gif') repeat;
    background-size: auto 100%;
}

header nav li a {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    padding: 12px 15px;
    font-weight: bold;
    font-size: 12px;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    text-decoration: none;
    text-transform: uppercase;
    text-align: left;
    color: #ffffff;

}

header nav li a:hover {
    color: white;
    text-decoration: none;
}

header nav li:hover {
    background: #003d77 url(/media/images/site/menu/top.gif) no-repeat bottom right;
}

header nav li > ul {
    position: absolute;
    width: 205px;
    z-index: 2;
    visibility: hidden;
    opacity: 0;

    transition-property: opacity, visibility;
    transition-duration: .4s, 0s;
    transition-delay: 0s, .4s;

}

header nav li > ul > li:first-of-type {
    border-top: 1px solid #002a5e !important;
}

header nav li > ul > li {
    width: 100%;
    float: left;
    display: list-item;
    background-color: #005996;
    border-left: 1px solid #002a5e;
    border-right: 1px solid #002a5e;
    border-top: 0 !important;
    border-bottom: 1px solid #002a5e;
}

header nav li > ul > li a {
    padding: 7px 8px 8px 14px;
}

header nav li.hasSub > a:after {
    content: url('/media/images/site/menu/top-sub.png');
    position: absolute;
    top: 50%;
    margin-top: -7px;
    right: 10px;
}

header nav li.hasSub:hover ul {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s, 0s;
}

header nav li.hasSub > a {
    padding: 12px 30px 12px 12px;
}

.middle-add {
    margin: 30px 0;
    overflow: hidden;
}

.bottom-add {
    margin: 15px 0;
    overflow: hidden;
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}

.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

section.wrap-content .main-content {
    padding: 0 0 15px 0;
}

section.wrap-content.list-article {
    padding-top: 15px;
}

section.wrap-content .main-content h1 {
    font-size: 44px;
    line-height: 50px;
}

section.wrap-content .main-content .author {
    font-size: 15px;
    color: #0d6a6b;
    margin-top: 10px;
    text-transform: uppercase;
}

section.wrap-content .main-content .author a {
    color: #eb1f27;
}

section.wrap-content .main-content .feature-item,
section.wrap-content .main-content .blog-item {
    margin-bottom: 15px;
    padding-bottom: 15px;
    overflow: hidden;
    position: relative;
}

section.wrap-content .main-content .feature-item a,
section.wrap-content .main-content .blog-item a {
    text-decoration: underline;
    color: #337ab7;
}

section.wrap-content .main-content .feature-item:after,
section.wrap-content .main-content .blog-item:after {
    content: "";
    display: block;
    width: 100%;
    left: 15px;
    right: 15px;
    height: 1px;
    border-bottom: dashed thin #ccc;
    position: absolute;
    bottom: 0;
}

.read-more {
    color: #eb1f27;
    font-style: italic;
    float: right;
    font-size: 16px;
}

section.wrap-content .main-content .blog-item h2 {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 27px;
}

.moreNews {
    margin-top: 0;
    padding-top: 0;
    float: left;
    width: 100%;
    overflow: hidden;
}

.home_page_top_article {
    width: 100%;
}

#load-more {
    background: url(/media/images/site/article_list/img_arrow_down.png) bottom no-repeat;
    height: 50px;
    margin-bottom: -8px;
    z-index: 1;
    position: relative;
    text-align: center;
    font-style: italic;
    background-size: 100%;
    font-weight: bold;
    color: #F00;
    font-size: 18px;
    cursor: pointer;
}

#patternRow {
    background: url(/media/images/site/footer/patternRow.png) repeat;
    width: 100%;
    height: 15px;
}

section.subscribe-section {
    margin: 15px 0;
}

section.wrap-content .sidebar p,
section.wrap-content .sidebar p a {
    color: #0d6aba;
}

section.wrap-content .sidebar p a:hover {
    color: #eb1f27;
}

section.wrap-content .sidebar .add {
    margin-bottom: 15px;
}

section.wrap-content .sidebar h2 {
    font-family: "dinot-regularregular", sans-serif;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
}

section.wrap-content .sidebar h2 .marexsub {
    color: #eb1f27;
    font-weight: bold;
}

section.wrap-content .sidebar .graybox {
    background-color: #F2F2F2;
    overflow: auto;
    border: 1px solid #cdcdcd;
    padding: 15px;
}

section.wrap-content .sidebar .graybox .topStoryTab {
    background: #fff;
    display: inline-block;
    padding: 10px;
    margin-bottom: 15px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    box-shadow: 5px 5px 5px rgba(128, 128, 128, .4);
}

section.wrap-content .sidebar .graybox .story {
    background: #fff;
    border: 1px solid #ccc;
    box-shadow: 5px 5px 5px rgba(128, 128, 128, .4);
    overflow: auto;
    margin-bottom: 15px;
    padding: 10px;
}

section.wrap-content .sidebar .graybox .story:last-of-type {
    margin-bottom: 0;
}

section.wrap-content .sidebar .graybox .story p {
    color: #0D6ABA;
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
    margin-bottom: 0;
}

section.wrap-content .sidebar .graybox .story .image {
    padding: 0;
}

section.wrap-content .sidebar .graybox .story .desc {
    padding-right: 0;
}

section.wrap-content .sidebar .graybox .blog-box:hover {
    box-shadow: none;
}

section.wrap-content .sidebar .graybox .blog-box {
    margin-bottom: 1.75em;
    background-size: cover !important;
    padding: 0;
}

section.wrap-content .sidebar .graybox .story {
    border-top: none;
}

section.wrap-content .sidebar .graybox .story img {
    width: 100%;
}

/**
BLOG SECTION START
 */
.sidebar .blog-box a figure {
    margin: 0;
    min-height: 155px;
    float: right;
    color: white;
    font-size: 24px;
    font-weight: bold;
    width: 100%;
}

#blog-law {
    background: url(/media/images/site/blog/MAREXLAW.jpg) no-repeat;
}

#blog-fin {
    background: url(/media/images/site/blog/MAREXfin.jpg) no-repeat;
}

#blog-rec {
    background: url(/media/images/site/blog/MAREXrec.jpg) no-repeat;
}

#blog-ed {
    background: url(/media/images/site/blog/MAREXed.jpg) no-repeat;
    margin-bottom: 4%;
}

#blog-tech {
    background: url(/media/images/site/blog/marinetech2.jpg) no-repeat;
    margin-bottom: 4%;
}

.sidebar .blog-box a figure {
    position: relative;
}

.sidebar .blog-box a figure p:hover {
    text-decoration: underline;
}

.sidebar .blog-box a figure p {
    color: white !important;
    position: absolute;
    bottom: 0;
    display: inline-table;
    width: 97%;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 3%;
    background: rgba(51, 51, 51, .7);
}

.sidebar .blog-box a figure p a {
    color: white;
}

/**
BLOG SECTION END
 */

.no-padding {
    padding: 0;
}

section.subscribe-section .row .wrap {
    background-color: #003399;
    color: white;
    overflow: hidden;
    text-align: left;
}

section.subscribe-section .row .wrap #subscribe_now {
    padding: 0;
}

section.subscribe-section .row a.sub-button {
    background-color: #eb1F27;
    padding: 15px;
    display: block;
    font-size: 18px;
    text-align: center;
    width: 100%;
    color: white;
}

section.subscribe-section ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

section.subscribe-section ul li {
    display: inline-block;
}

section.subscribe-section ul li a {
    color: white;
    font-style: italic;
    display: block;
    font-size: 16px;
    padding: 15px;
}

section.subscribe-section p {
    margin-top: 15px;
}

section.subscribe-section p,
section.subscribe-section a.button {
    color: white;
    font-size: 18px;
}

footer .row {
    padding: 20px 0;
    text-align: center;
}

footer .patternRow {
    background: url(/media/images/site/footer/patternRow.png) repeat;
    width: 100%;
    height: 15px;
}

footer ul {
    list-style-type: none;
    text-align: center;
    padding: 0;
    margin: 20px auto;
}

footer ul li {
    display: inline-block;
    position: relative;
}

footer ul li:after {
    content: "|";
    margin-left: 15px;
    margin-right: 10px;
    color: #ccc;
}

footer ul li:last-of-type:after {
    display: none;
}

footer ul li a {
    color: #666;
    font-size: 14px;
    text-transform: uppercase;
}

footer p {
    color: #ccc;
    font-size: 14px;
}

footer ul li a:hover {
    color: #ccc;
    text-decoration: none;
}

section .news {
    margin-bottom: 20px;
}

section .news .big-post {
    margin-top: 30px;
}

section .news.small h4 a {
    color: #0D6ABA;
}

section .news a,
section .news a i {
    font-size: 14px;
    color: #0d6aba;
    line-height: 18px;
}

section .news .big-post div.desc {
    min-height: 136px;
}

section .news a i {
    font-size: 16px;
}

section .news.small .big-post {
    margin-top: 0;
}

section .news.small .big-post .image {
    min-height: 183px;
}

section .news.small a i {
    font-size: 14px;
}

section .news.small h4 {
    position: relative;
}

section .news.small h4 a {
    font-size: 12px;
    padding-left: 10px;
}

section .news.small h4:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 3px;
    float: left;
    font-size: 12px;
    text-decoration: underline;
    height: 14px;
    width: 6px;
    background-color: #eb1f27;
}

section .news h4:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 2px;
    float: left;
    font-size: 16px;
    text-decoration: underline;
    height: 16px;
    width: 6px;
    background-color: #eb1f27;
}

section .news h4 {
    position: relative;
}

section .news h4 a {
    color: #565656;
    padding-left: 15px;
    text-transform: uppercase;
}

.gray {
    color: #ccc;
}

section .news ul {
    margin-top: 20px;
}

section .news ul li:first-of-type a {
    border-top: dashed thin #ccc;
}

section .news ul li a {
    padding: 13px 0;
    display: block;
    border-bottom: dashed thin #ccc;
    min-height: 64px;
}

.no-left-padding {
    padding-left: 0;
}

section.news-wrap {
    margin-top: 15px;
    margin-bottom: 15px;
}

section.news-wrap .tugSalvageLink a {
    font-size: 16px;
    color: #0d6aba;
}

.events {
    background-color: #BBE7FF;
    width: 100%;
    padding: 0 15px 30px 15px;
    overflow: hidden;
    margin-bottom: 50px;
}

.events .patternHome {
    background: url(/media/images/site/menu/blue_top.png) repeat;
    width: 100vw;
    height: 15px;
    margin-left: -6vw;
    margin-right: -15px;
}

.events table {
    width: 100%;
    border: 1px solid #0D6ABA;
}

.events table a {
    color: #eb1f27;
}

.events table tr {
    border-bottom: 1px solid #BBE7FF;
    background: #fff;
    width: 100%;
    cursor: pointer;
}

.events table tbody tr:hover {
    background-color: #ccc;
}

.events table thead tr {
    background-color: #97C8F1;
}

.events table tr th {
    padding: 3px 15px;
    color: #565656;
    text-transform: uppercase;
}

.events table tr td {
    padding: 15px;
    font-size: 16px;
}

.events h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.events h2 span.red {
    color: #CA242B;
    font-weight: bold;
}

.events h2 span.dark {
    color: #333;
    font-weight: bold;
}

.events .months {
    text-align: center;
    margin-top: 10px;
}

.events .months li {
    display: inline-block;
}

.events .months li {
    padding: 0 10px;
    position: relative;
}

.events .months li:last-of-type:after {
    display: none;
}

.events .months li:after {
    content: "|";
    position: absolute;
    right: -4px;
    top: -1px;
}

.events .months li a {
    font-size: 14px;
    color: #0d6aba;
}

.search-bar {
    padding: 10px;
    border: 1px solid #ccc;
    overflow: hidden;
    border-radius: 4px;
    margin-bottom: 20px;
}

.search-bar input {
    width: 100%;
}

.search-bar input[type=search] {
    padding: 10px 15px;
    outline: 0;
    border: 1px solid silver;
    font-size: 18px;
}

.search-bar input[type=submit],
.search-bar input[type=reset] {
    background: #0D6ABA;
    border: 1px solid #666666;
    border-radius: 4px;
    font-size: 20px;
    margin-top: 15px;
    padding: 5px 15px;
    color: white;
}

.search-bar input[type=submit]:hover,
.search-bar input[type=reset]:hover {
    background: #B2B2B2;
    border: 1px solid #ccc;
}

.bold {
    font-weight: bold;
}

.red {
    color: #eb1f27;
}

.alpha_bar .alpha {
    background: #0D6ABA;
    border: 1px solid #666666;
    border-radius: 4px;
    color: white;
    padding: 2px 2px;
    width: 25px;
    text-align: center;
    margin-bottom: 10px;
    margin-right: 8px;
    margin-left: 8px;
}

.directory-list {
    overflow: hidden;
    margin-top: 15px;
}

.directory-list .directory-box {
    margin-bottom: 15px;
}

.directory-list .directory-box .directory-header {
    background: #a2cff9;
    background: -moz-linear-gradient(top, #a2cff9 0%, #56adfa 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #a2cff9), color-stop(100%, #56adfa));
    background: -webkit-linear-gradient(top, #a2cff9 0%, #56adfa 100%);
    background: -o-linear-gradient(top, #a2cff9 0%, #56adfa 100%);
    background: -ms-linear-gradient(top, #a2cff9 0%, #56adfa 100%);
    background: linear-gradient(to bottom, #a2cff9 0%, #56adfa 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a2cff9', endColorstr='#56adfa', GradientType=0);
    padding: 13px 20px;
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid #56adfa;
}

.directory-list .directory-box .directory-header h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 0;
    margin-top: 0;
}

.directory-list .directory-box .directory-header h2 a:hover {
    text-decoration: none;
}

.directory-list .directory-box .directory-desc {
    background: #e3f1fe;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid #ccc;
    overflow: hidden;
    padding: 15px;
}

.directory-list .directory-box .directory-desc p {
    font-size: 16px;
    margin-bottom: 5px;
}

.directory-list .directory-box .directory-desc a {
    font-size: 14px;
    color: #0d6aba;
    margin-bottom: 5px;
}

.directory-list .directory-box .directory-desc a:hover {
    color: #eb1f27;
    text-decoration: underline;
}

section.wrap-content .main-content.features .blog-item:after {
    display: none;
}

section.wrap-content .main-content.features {
    padding-top: 30px;
}

section.wrap-content .main-content.features .blog-item h2 {
    margin-top: 10px;
}

section.wrap-content .main-content.features .author {
    font-size: 13px;
}

section.wrap-content .main-content.features .blog-item:nth-child(3n+1) {
    clear: left;
}

.digital-wrap {
    padding: 15px 0;
    background: #333;
    overflow: hidden;
    color: white;
}

.digital-wrap h3,
.digital-wrap h2,
.digital-wrap a,
.digital-wrap ul li a,
.digital-wrap p {
    color: white;
}

.digital-wrap h3 {
    margin-top: 0;
    margin-bottom: 10px;
}

.digital-wrap h3 a {
    color: #0d6aba;
}

.digital-wrap h2 {
    margin-top: 10px;
    font-size: 16px;
}

.digital-wrap p {
    margin-bottom: 30px;
}

.digital-wrap ul {

}

.digital-wrap ul li {
    list-style-type: disc;
    color: #0d6aba;
}

.digital-wrap ul li a {
    margin-top: 10px;
    font-size: 16px;
    color: #0d6aba;
}

.digital-wrap .line {
    display: block;
    background-color: white;
    height: 3px;
    width: 100%;
    margin-bottom: 30px;
}

.digital-wrap .button {
    display: block;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    color: white;
    margin-top: 10px;
}

.digital-wrap .button {
    text-decoration: none;
}

.digital-wrap .button.red {
    background-color: #eb1F27;
}

.digital-wrap .button.blue:hover {
    background-color: #002b56;
}

.digital-wrap .button.blue {
    background-color: #1e4c7d;
}

.digital-wrap .button.red:hover {
    background-color: #990000;
}

.digital-wrap {
    margin-bottom: 50px;
}

.editions {
    margin-bottom: 40px;
    border-radius: 6px;
    border: 1px solid #ccc;
    background: #e6e6e6;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJod…EiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #e6e6e6 0%, #f5f5f5 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e6e6e6), color-stop(100%, #f5f5f5));
    background: -webkit-linear-gradient(top, #e6e6e6 0%, #f5f5f5 100%);
    background: -o-linear-gradient(top, #e6e6e6 0%, #f5f5f5 100%);
    background: -ms-linear-gradient(top, #e6e6e6 0%, #f5f5f5 100%);
    background: linear-gradient(to bottom, #e6e6e6 0%, #f5f5f5 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e6e6e6', endColorstr='#f5f5f5', GradientType=0);
    padding: 15px 5px;
    overflow: hidden;
}

.editions .wrap-boxes {
    display: flex;
}

.editions .box {
    padding: 10px;

}

.editions h3 {
    margin-left: 15px;
    margin-bottom: 5px;
}

.editions .inner {
    padding: 10px;
    height: 100%;
    border: 2px solid white;
    background-color: #e4e4e4;
}

.editions .box:hover .inner {
    box-shadow: inset 0 0 20px #999;
    border: 2px solid #999;
}

.editions h3 {
    margin-top: 0;
}

.editions p.silver {

}

.editions .image {
    margin-bottom: 10px;
}

.editions p {
    margin-bottom: 0;
    line-height: 17px;
    color: black;
    text-transform: uppercase;
    font-size: 12px;
    text-align: center;
}

.editions .box p:nth-child(2) {
    color: #6f6e73;
}

/* social media sidebar css */

div.sprite {
    width: 14%;
    height: 0;
    padding-bottom: 20%;
    background-image: url("/media/images/site/social_sprite.png");
    background-size: 698%;
    display: block;
    float: left;
    cursor: pointer;
    z-index: 9999;
}

#social-follow {
    margin-bottom: 1em;
}

#magazine_edition_title {
    margin-left: 2%;
    margin-bottom: 2%;
}

#page_title {
    font-size: 2.75em;
    margin-left: 2%;
}

#sprite_mail {
    background-position: 0 0;
}

#sprite_facebook {
    background-position: 50% 0;
}

#sprite_twitter {
    background-position: 32% 0;
}

#sprite_linkedin {
    background-position: 16% 0;
}

#sprite_google {
    background-position: 67% 0;
}

#sprite_reddit {
    background-position: 84% 0;
}

#sprite_rss {
    background-position: 100% 0;
}

#sprite_mail:hover {
    background-position: 0 100%; /* Use ONLY percents here */
}

#sprite_facebook:hover {
    background-position: 50% 100%; /* Use ONLY percents here */
}

#sprite_twitter:hover {
    background-position: 32% 100%; /* Use ONLY percents here */
}

#sprite_linkedin:hover {
    background-position: 16% 100%; /* Use ONLY percents here */
}

#sprite_google:hover {
    background-position: 67% 100%; /* Use ONLY percents here */
}

#sprite_reddit:hover {
    background-position: 84% 100%; /* Use ONLY percents here */
}

#sprite_rss:hover {
    background-position: 100% 100%; /* Use ONLY percents here */
}

#social_facebook {
    margin-left: 5px;
}

#social_twitter {
    margin-left: 41px;
}

#social_google {
    margin-left: 146px;
}

#social_reddit {
    margin-left: 183px;
}

#social_rss {
    float: right;
}

#google_search_bar {
    margin: 0;
    float: none;
}

#block-title {
    text-align: center;
    font-size: 32px;
    line-height: 18px;
    margin-bottom: 18px;
    margin-top: 3px;
}

.dfp_ad_block {
    float: left;
    width: 100%;
    text-align: center;
}

.dfp_mobi {
    margin-bottom: 3%;
}

#ad_990x90_1 {
    margin-top: 16px;
}

#ad_990x90_2 {
    margin-top: 2em;
    margin-bottom: 2em;
    text-align: center;
}

#linkedin_info {
    text-align: center;
    color: #565656;
}

#social-follow #social_buttons {
    border-top: 7px solid #d6d6d6;
    margin-top: 20%;
    padding-top: 5%;
    position: relative;
}

#social-follow #social_buttons li#social_mail {
    padding-left: 20px;
}

#social-follow #social_buttons li#social_mail input {
    float: left;
    height: 32px;
    font-style: italic;
    font-size: 16px;
    margin-bottom: 8px;
}

#social-follow #social_buttons li#social_mail .np {
    clear: both;
    font-size: 12px;
    color: #666;
}

#social-follow #social_buttons li#social_mail .np-error {
    color: #ee2a26;
}

#social-follow #social_buttons li#social_mail input.form-text {
    background-color: #f2f2f2;
    width: 159px;
    padding-left: 9px;
    color: #666;
}

#social_buttons li#social_mail input.form-submit {
    color: #fff;
    background-color: #000;
    width: 92px;
    font-family: "dinot-regularregular", sans-serif;
}

#social-follow #social_buttons li iframe {
    float: left;
    max-width: 150px;
}

#social-follow #social_buttons {
    min-height: 34px;
}

.search-article {
    padding: 10px;
    border: 1px solid silver;
    border-radius: 4px;
    margin-top: 30px;
}

.search-article .btn-group {
    width: 100%;
}

.search-article select {
    height: 42px;
    font-size: 20px;
    padding: 0 10px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
}

.search-article select option {
    color: #fff;
}

.search-article button.btn-default {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
    font-size: 20px;
}

.search-article .dropdown-menu {
    min-width: 100%;
}

.no-right-padding {
    padding-right: 0;
}

.search-article input[type=button] {
    padding: 10px;
    background: #0D6ABA;
    color: white;
    margin-right: 0;
    border: 1px solid #666666;
    text-align: center;
    border-radius: 4px;
    width: 235px;
}

.search-article input {
    margin-right: 20px;
}

.search-article label {
    font-size: 20px;
    font-weight: normal;
}

/* magazine css */

section.magazine-section-one .row {
    padding-top: 25px;
    padding-bottom: 15px;
}

section.magazine-section-one h2 {
    font-size: 28px;
    color: #808184;
    font-family: 'DINOT regular', serif;
    margin-top: 0;
    margin-bottom: 20px;
}

section.magazine-section-one h2 span {
    font-family: "Arial black", serif;
}

section.magazine-section-one p,
section.magazine-section-one p span,
section.magazine-section-one p span.italic {
    font-family: 'Minion Pro regular', serif;
    font-size: 18px;
    line-height: 25px;
    color: #6d6e71;
}

section.magazine-section-one p span.italic {
    font-family: 'Minion Pro italic', serif;
}

section.magazine-section-one .col-md-6:first-of-type {
    padding: 0 30px 0 0;
}

section.magazine-section-one .col-md-6:last-of-type {
    padding: 0 0 0 30px;
}

section.magazine-slider {
    background-color: #434143;
}

section.magazine-slider .row {

}

section.magazine-slider .slider .slide:active,
section.magazine-slider .slider .slide:focus,
section.magazine-slider .slider .slide:hover,
section.magazine-slider .slider .slide.black-bg {
    background-color: #000000;
    outline: none;
    text-decoration: none;
}

section.magazine-slider .slider .slide {
    text-align: center;
    padding: 30px 30px 20px 30px;
}

section.magazine-slider .slider .slide h4 {
    font-size: 11px;
    color: #bcbdbf;
    text-transform: uppercase;
    font-family: 'DINOT medium', serif;
}

section.magazine-slider .slider .slide img {
    margin: 0 auto;
}

section.magazine-slider .slider .slick-arrow {
    position: absolute;
    top: 50%;
    margin-top: -16px;
    width: 32px;
    height: 63px;
    border: 0;
    display: block;
    z-index: 3;
    text-indent: -9999px;
    background: transparent no-repeat;
}

section.magazine-slider .slider .slick-prev.slick-arrow {
    background-image: url('/media/images/site/magazine/prev.png');
    left: -30px;
    margin-top: -16px;
}

section.magazine-slider .slider .slick-next.slick-arrow {
    background-image: url('/media/images/site/magazine/next.png');
    right: -30px;
}

section.magazine-digital-edition {
    position: relative;
}

section.magazine-digital-edition .magazine-background-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

section.magazine-digital-edition .tint:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    z-index: 0;
    background-color: rgba(0, 51, 102, 0.8);
}

section.magazine-digital-edition .row {
    padding: 30px 0;
}

section.magazine-digital-edition p span.bold,
section.magazine-digital-edition p {
    font-size: 18px;
    color: white;
    font-family: 'DINOT regular', serif;
    margin-bottom: 0;
}

section.magazine-digital-edition .button {
    display: inline-block;
    border-radius: 10px;
    padding: 15px 25px;
    line-height: 18px;
    background-color: #e92429;
    color: white;
    text-align: center;
    font-size: 18px;
    font-family: 'DINOT bold', serif;
}

section.magazine-digital-edition .button:hover {
    text-decoration: none;
    background-color: #cd2328;
}

section.magazine-digital-edition p,
section.magazine-digital-edition .button {
    margin-top: 20px;
}

section.magazine-article-issue .row {
    padding: 25px 0;
    display: flex;
}

section.magazine-article-issue .col-md-4 {
    border-right: 1px solid #d3d4d5;
}

section.magazine-article-issue .col-md-4:last-of-type {
    border: 0;
}

section.magazine-article-issue h4 {
    color: #db9d27;
    font-size: 19px;
    font-family: 'DINOT bold', serif;
    margin-top: 0;
    text-transform: uppercase;
}

section.magazine-article-issue ul {
    margin-bottom: 0;
}

section.magazine-article-issue ul li {

    border-bottom: 1px solid #d3d4d5;
}

section.magazine-article-issue ul li:first-of-type {
    padding-top: 0;
}

section.magazine-article-issue ul li a {
    padding: 8px 0;
}

section.magazine-article-issue ul li a:hover {
    background-color: #f2f2f2;
    text-decoration: none;
}

section.magazine-article-issue ul li a span.bold,
section.magazine-article-issue ul li a {
    color: #003366;
    font-size: 15px;
    display: block;
    font-family: 'DINOT regular', serif;
}

section.magazine-article-issue ul li a span.bold {
    font-family: 'DINOT bold', serif;
}

.wrap-mag-images {
    display: flex;
}

.wrap-mag-images .col-md-4 {
    padding-right: 0;
}

.wrap-mag-images .col-md-8 {
    padding-left: 0;
}

.wrap-mag-images img {
    height: 100%;
    width: 100%;
}

/* end of magazine css */

/* end of social media bar */

@media (max-width: 992px) {
    section.subscribe-section .row .wrap {
        text-align: center;
    }

    .container {
        width: 100%;
    }

    .row {
        margin: 0;
    }

    header .ribbon .container {
        padding: 0;
    }

    header .row nav > ul {
        list-style-type: none;
        display: block;
    }

    header .row ul li {
        display: block;
        border-left: 1px solid #002a5e;
        border-top: 1px solid #002a5e;
        border-bottom: 1px solid #002a5e;
        position: relative;
    }

    section.wrap-content .container {
        padding-left: 7px;
        padding-right: 7px;
    }

    header nav {
        display: none;
        margin-top: 15px;
    }

    header .ribbon .row {
        padding: 15px 0;
    }

    header nav li > ul {
        position: relative;
        width: 100%;
        visibility: visible;
        opacity: 1;
        display: none;
    }

    header .row ul li.hasSub:hover ul {
        display: block;
    }

    section.magazine-section-one p, section.magazine-section-one p span.italic {
        font-size: 15px;
        line-height: 20px;

    }

    section.magazine-section-one .col-md-6:first-of-type {
        padding: 0;
    }

    section.magazine-section-one .col-md-6:last-of-type {
        padding: 0;
    }

    section.magazine-section-one h2 {
        font-size: 24px;
        margin-bottom: 10px;
    }

    section.magazine-slider .slider .slide {
        padding: 15px 10px 15px 10px;
    }

    section.magazine-slider .slider {
        padding: 0 15px;
    }

    section.magazine-slider .slider .slick-next.slick-arrow {
        right: -5px;
    }

    section.magazine-slider .slider .slick-prev.slick-arrow {
        left: -5px;
    }

    section.magazine-digital-edition p, section.magazine-digital-edition .button {
        margin-top: 10px;
    }

    section.magazine-digital-edition .col-sm-12 {
        text-align: center;
    }

    section.magazine-digital-edition .button {
        float: none;
        margin-top: 20px;
    }

    section.magazine-article-issue h4 {
        font-size: 16px;
    }

    section.magazine-article-issue .col-md-4 .col-md-12 {
        padding: 0;
    }

}

@media (max-width: 767px) {

    #load-more {
        margin-bottom: -10px;
    }

    .no-padding-xs {
        padding: 0;
    }

    .search-article select {
        margin-top: 10px;
        border-radius: 4px;
    }

    .search-article .btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
        border-radius: 0 4px 4px 0;
    }

    .editions .wrap-boxes {
        display: block;
    }

    .blog-item.big {
        padding: 15px;
    }

    .digital-wrap .col-md-4 {
        margin-bottom: 30px;
    }

    .digital-wrap ul {
        margin-left: 15px;
    }

    .search-article div {
        padding: 0;
    }

    section.wrap-content .main-content.features .blog-item .desc {
        padding: 0;
    }

    section.wrap-content .main-content.features .blog-item .col-md-12 {
        padding: 0;
    }

    .blog-item.big h2 {
        margin-top: 15px !important;
    }

    .read-more {
        color: white;
        float: none;
        text-align: center;
        font-weight: bold;
        padding: 2%;
        font-size: 16px;
        display: block;
        background-color: #9DCEFF;
    }

    section.wrap-content .main-content .blog-item h2 {
        margin-top: 10px;
    }

    section.wrap-content .main-content h1 {
        font-size: 25px;
        line-height: 32px;
    }

    header .wrap-search input[type=submit] {
        min-width: 50px;
        margin-left: 8px;
    }

    section.wrap-content .main-content .blog-item {
        padding: 0 15px;
    }

    .search-bar div {
        padding: 0;
    }

    .directory-list .directory-box .directory-desc .details {
        padding: 0;
        margin-top: 10px;
    }

    .directory-list .directory-box .directory-desc .image {
        padding: 0;
    }

    .logo {
        width: 225px;
        margin: 0 auto;
        float: none;
        display: block;
    }

    header .row {
        padding: 15px 0;
    }

    header .row .wrap-search {
        margin-top: 15px;
    }

    section.magazine-section-one {
        text-align: center;
    }

    section.magazine-section-one .row {
        padding: 15px 25px;
    }

    .read-more-magzine {
        color: #ed2024;
        font-size: 15px;
        font-family: 'DINOT bold', serif;
        float: right;
        position: relative;
        padding-right: 25px;
    }

    .read-more-magzine:after {
        content: url('/media/images/site/magazine/plus.png');
        position: absolute;
        right: 0;
        top: 2px;
    }

    section.magazine-section-one div {
        padding: 0;
    }

    section.magazine-section-one h2 {
        font-size: 23px;
    }

    section.magazine-section-one p span.italic {
        font-family: 'DINOT regular', serif;
        font-style: normal;
    }

    section.magazine-slider .slider {
        padding: 0;
    }

    section.magazine-slider .container {
        padding: 0;
    }

    section.magazine-slider .slider .slide {
        padding: 15px 15px 5px 15px;
    }

    section.magazine-slider .slider .slick-arrow {
        width: 24px;
        height: 66px;
    }

    section.magazine-slider .slider .slick-prev.slick-arrow {
        background-image: url(/media/images/site/magazine/prev-mobile.png);
        left: 0;
        margin-top: -33px;
    }

    section.magazine-slider .slider .slick-next.slick-arrow {
        background-image: url(/media/images/site/magazine/next-mobile.png);
        right: 0;
        margin-top: -33px;
    }

    section.magazine-slider .slider .slide h4 {
        font-size: 9px;
    }

    section.magazine-article-issue .row {
        display: block;
    }

    section.magazine-article-issue .row {
        padding: 0;
    }

    section.magazine-article-issue .row .col-md-4,
    section.magazine-article-issue .container {
        padding: 0;
    }

    section.magazine-article-issue .digital-edition-button {
        background: #e92429 url('/media/images/site/magazine/arrow-white.png') no-repeat right center;
        font-size: 15px;
        font-family: 'DINOT bold', serif;
        color: white;
        width: 100%;
        display: block;
        padding: 10px 9px;
        border-top: 1px solid #ec4e84;
    }

    section.magazine-article-issue h4 {
        text-align: center;
        width: 100%;
        font-size: 15px;
        color: #40baec;
        background-color: #1c3664;
        padding: 12px;
        margin-bottom: 0;
    }

    section.magazine-article-issue .second ul li a {
        font-size: 15px;
        color: white;
        font-family: 'DINOT bold', serif;
        padding: 12px 40px 12px 12px;
        display: block;
        background: #17488a url('/media/images/site/magazine/arrow-blue.png');
        background-repeat: no-repeat;
        background-position: right center;
    }

    section.magazine-article-issue .second ul li a:hover {
        background-color: #15427e;
    }

    section.magazine-article-issue .third ul li a:hover {
        background-color: #066284;
    }

    section.magazine-article-issue .second ul li {
        padding: 0;
    }

    section.magazine-article-issue .digital-edition-button:hover {
        background-color: #c01d21;
    }

    section.magazine-article-issue .second ul li {
        border-top: 1px solid #1c5baa;
        border-bottom: 1px solid #1c3664;
    }

    section.magazine-article-issue .second ul li a span {
        color: white;
    }

    section.magazine-article-issue .third ul li a {
        font-size: 16px;
        color: white;
        font-family: 'DINOT regular', serif;
        padding: 12px 40px 12px 12px;
        display: block;
        background: #07698d url('/media/images/site/magazine/arrow-green.png') no-repeat right center;
    }

    section.magazine-article-issue .third ul li {
        padding: 0;
    }

    section.magazine-article-issue .third ul li {
        border-top: 1px solid #0485b2;
        border-bottom: 1px solid #1b3967;
    }

    section.magazine-article-issue .col-md-4 {
        border: 0;
    }

    section.magazine-article-issue .third ul li a span {
        color: white;
    }

}


